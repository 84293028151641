import React, { useEffect, useState } from "react";
import { LoaderDiv, LoaderWrapper } from "./element";
import { Image } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import {
  loaderBricks,
  loaderEye,
  loaderHeadTriangle,
  loaderPlainTriangle,
} from "assets";

function Loader() {
  const [showEyesImg, setShowEyedImg] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowEyedImg((prev) => !prev);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const animationVariants = {
    forward: { y: -10 },
    backward: { y: 5 },
  };

  const animationProps = {
    variants: animationVariants,
    initial: "forward",
    animate: "backward",
    transition: {
      duration: 0.5,
      ease: "linear",
      repeat: Infinity,
      repeatType: "mirror",
    },
  };

  return (
    <LoaderWrapper>
      <LoaderDiv>
        <motion.div {...animationProps}>
          <Image src={showEyesImg ? loaderEye : loaderHeadTriangle} />
        </motion.div>

        <Image src={showEyesImg ? loaderBricks : loaderPlainTriangle} />
      </LoaderDiv>
    </LoaderWrapper>
  );
}

export default Loader;
