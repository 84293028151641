import { createGlobalStyle } from "styled-components";
import TimeNewRoman from "./assets/fonts/timesNewRoman.ttf";
import DollarBill from "./assets/fonts/DollarBill.otf";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "Dollar Bill";
  src: url(${DollarBill});

}
@font-face {
  font-family: "Times New Roman";
  src: url(${TimeNewRoman});
}


  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  } 

 body
{
  overflow-x: hidden !important;
  background-color: #d7dccd;
}


h1 {
  font-family: "Dollar Bill" !important;
  line-height: normal !important;
}

p {
  font-family: "Times New Roman" !important;
  line-height: normal !important;
}


.main-container {
  max-width: 1750px;
  margin: 0 auto;
  
  @media (max-width:1750px) {
    padding-left: 4px;
    padding-right: 4px;
    
  }
}



/* Loader */
.cover-spin {
 position: fixed;
 width: 100%;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 background:rgba(0,0,0,0.4);
 z-index: 999999;
 display: flex;
 justify-content: center;
 align-items: center;
}


 
/* Models styles  */
.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }
  .ant-modal-content
  {
   background: none;
   box-shadow: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }
  .closeModelInput
  {
    transform: translateY(-2px);
  }
 
 
`;

export default GlobalStyle;
