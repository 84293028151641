import { CommonUtility } from "utility/common";
import { stakingAbi } from "utility/contract/stakingAbi";

class Staking {
  //   <<<<--- READ FUNCTIONS --->>>>

  async getUserStakes(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(
        web3,
        stakingAbi,
        contractAddress
      );
      return await contract.methods.getuserStakes(account).call();
    } catch (error) {
      console.log("error in getuserStakes func", error);
      return error;
    }
  }
  async calculateRewards(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(
        web3,
        stakingAbi,
        contractAddress
      );
      return await contract.methods.calculateRewards(account).call();
    } catch (error) {
      console.log("error in calculateRewards func", error);
      return error;
    }
  }

  //   <<<<--- WRITE FUNCTIONS ---->>>>

  async stakeToken(web3, amount, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(
        web3,
        stakingAbi,
        contractAddress
      );
      return await contract.methods.stakeToken(amount).send({ from: account });
    } catch (error) {
      console.log("error in stakeToken func", error);
      return error;
    }
  }
  async claimRewards(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(
        web3,
        stakingAbi,
        contractAddress
      );
      return await contract.methods.claimRewards().send({ from: account });
    } catch (error) {
      console.log("error in claimRewards func", error);
      return error;
    }
  }
  async withdraw(web3, amount, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(
        web3,
        stakingAbi,
        contractAddress
      );
      return await contract.methods.withdraw(amount).send({ from: account });
    } catch (error) {
      console.log("error in withdraw func", error);
      return error;
    }
  }
}

const StakingService = new Staking();
Object.freeze(StakingService);
export default StakingService;
