import React, { useEffect, useState } from "react";
import { Label, WithdrawWrapper } from "./element";
import { Form, Image } from "react-bootstrap";
import { Modal } from "antd";
import { commonBtn } from "assets";
import { useForm, Controller } from "react-hook-form";
import { useWalletConnectClient } from "services/walletServices";
import ConnectModal from "components/common/modal/connectModel/connectModal";
import StakingService from "web3Functions/staking";
import environment from "environment";
import { Loader, ToastMessage } from "components/common";
import { CommonUtility } from "utility/common";

function Withdraw() {
  const [connectModal, setConnectModal] = useState(false);
  const [userStakedAmount, setUserStakedAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const { account, web3Provider, chain } = useWalletConnectClient();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });

  const handleModalOpen = () => {
    setConnectModal(true);
  };
  const handleModalCancel = () => {
    setConnectModal(false);
  };

  const getUserStakedAmount = async () => {
    try {
      const userStakes = await StakingService.getUserStakes(
        web3Provider,
        account,
        environment.STAKING_CONTRACT[+chain]
      );
      const stakeAmount = web3Provider.utils.fromWei(
        userStakes.amount,
        environment.ANNUIT_DECIMALS_UNIT
      );
      setUserStakedAmount(stakeAmount);
      return userStakes.amount;
    } catch (error) {
      console.log("error in getUserStakedAmount func", error);
    }
  };

  const handleWithdraw = async (amount) => {
    try {
      setLoading(true);
      const stakeTokens = await getUserStakedAmount();
      const amountInWei = web3Provider.utils.toWei(
        amount,
        environment.ANNUIT_DECIMALS_UNIT
      );

      if (Number(stakeTokens) < Number(amountInWei))
        throw "Insufficient Amount to withdraw!";
      const withdrawTnx = await StakingService.withdraw(
        web3Provider,
        amountInWei,
        account,
        environment.STAKING_CONTRACT[+chain]
      );
      if (withdrawTnx?.code === 4001) throw "User denied transaction!";
      if (!withdrawTnx.status) throw "Transaction Failed";
      if (withdrawTnx.status) {
        await getUserStakedAmount();
        ToastMessage("Success!", "Transaction successfull", "success");
      }
      setLoading(false);
    } catch (error) {
      console.log("error in handleWithdraw func", error);
      setLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const { amount } = data;
      await handleWithdraw(amount);
      setValue("amount", "");
    } catch (error) {
      console.log("error in onSubmit func", error);
    }
  };

  useEffect(() => {
    if (web3Provider && account && chain) {
      getUserStakedAmount();
    }
  }, [web3Provider, account, chain]);

  return (
    <WithdrawWrapper>
      {loading && <Loader />}
      <Modal
        centered
        width={700}
        footer={false}
        open={connectModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
      >
        <ConnectModal closeModel={handleModalCancel} />
      </Modal>
      <div className="inner-div">
        <Label>Amount staked</Label>
        <Label>
          {userStakedAmount
            ? `${CommonUtility.numberWithCommas(userStakedAmount)} A_C`
            : "--"}
        </Label>
      </div>
      <Label className="mt-4">Withdraw Amount:</Label>
      <div className="mt-2 form-relative-div">
        <Controller
          name="amount"
          control={control}
          rules={{
            required: "Amount is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "Invalid amount entered",
            },
            validate: {
              notZero: (value) =>
                parseFloat(value) !== 0 || "Amount must not be zero",
              sufficientAmount: (value) =>
                parseFloat(value) <= parseFloat(userStakedAmount) ||
                "Withdraw amount exceeds",
            },
          }}
          render={({ field }) => (
            <Form.Control {...field} placeholder="0" disabled={!web3Provider} />
          )}
        />
        <Label
          className="cursor-label"
          onClick={() => {
            web3Provider &&
              userStakedAmount &&
              setValue("amount", userStakedAmount);
          }}
        >
          MAX
        </Label>
      </div>
      {errors && (
        <p className="error" style={{ color: "red", marginBottom: "0rem" }}>
          {errors.amount?.message}
        </p>
      )}

      {web3Provider ? (
        <div
          className="btn-div address-btn-div"
          onClick={handleSubmit(onSubmit)}
        >
          <Image src={commonBtn} alt="button bg" />
          <h1>Withdraw</h1>
        </div>
      ) : (
        <div className="btn-div address-btn-div" onClick={handleModalOpen}>
          <Image src={commonBtn} alt="button bg" />
          <h1>Wallet</h1>
        </div>
      )}
    </WithdrawWrapper>
  );
}

export default Withdraw;
