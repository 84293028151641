import React, { useEffect, useState } from "react";
import { Label, SmallText, YearlyAPYDiv } from "./element";
import { Form, Image } from "react-bootstrap";
import { Modal } from "antd";
import { commonBtn } from "assets";
import ConnectModal from "components/common/modal/connectModel/connectModal";
import { useWalletConnectClient } from "services/walletServices";
import { useForm, Controller } from "react-hook-form";
import { ERC20Service } from "web3Functions";
import environment from "environment";
import StakingService from "web3Functions/staking";
import { Loader, ToastMessage } from "components/common";
import { CommonUtility } from "utility/common";

function Stake() {
  const [connectModal, setConnectModal] = useState(false);
  const [userBalance, setUserBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const { account, web3Provider, chain } = useWalletConnectClient();
  const handleModalOpen = () => {
    setConnectModal(true);
  };
  const handleModalCancel = () => {
    setConnectModal(false);
  };

  const getUserBalance = async () => {
    try {
      const balanceInWei = await ERC20Service.balance(
        web3Provider,
        account,
        environment.ANNUIT_CONTRACT[+chain]
      );
      const balance = web3Provider.utils.fromWei(
        balanceInWei,
        environment.ANNUIT_DECIMALS_UNIT
      );
      console.log("balance", balance);
      setUserBalance(balance);
    } catch (error) {
      console.log("error in getUserBalance func", error);
    }
  };

  const handleStaking = async (amount) => {
    try {
      setLoading(true);
      const balance = await ERC20Service.balance(
        web3Provider,
        account,
        environment.ANNUIT_CONTRACT[+chain]
      );
      const amountInWei = web3Provider.utils.toWei(
        amount,
        environment.ANNUIT_DECIMALS_UNIT
      );
      if (Number(balance) < Number(amountInWei)) throw "Insufficient balance!";
      const allowance = await ERC20Service.allowance(
        web3Provider,
        account,
        environment.STAKING_CONTRACT[+chain],
        environment.ANNUIT_CONTRACT[+chain]
      );
      if (Number(allowance) < Number(amountInWei)) {
        const totalSupply = await ERC20Service.totalSupply(
          web3Provider,
          environment.ANNUIT_CONTRACT[+chain]
        );
        const approveTxn = await ERC20Service.approve(
          web3Provider,
          environment.STAKING_CONTRACT[+chain],
          totalSupply,
          account,
          environment.ANNUIT_CONTRACT[+chain]
        );
        if (approveTxn?.code === 4001) throw "User denied transaction!";
        const allowanceAfter = await ERC20Service.allowance(
          web3Provider,
          account,
          environment.STAKING_CONTRACT[+chain],
          environment.ANNUIT_CONTRACT[+chain]
        );
        if (Number(allowanceAfter) < Number(amountInWei))
          throw "Insufficient allowance!";
      }
      const stakingTxn = await StakingService.stakeToken(
        web3Provider,
        amountInWei,
        account,
        environment.STAKING_CONTRACT[+chain]
      );
      if (stakingTxn?.code === 4001) throw "User denied transaction!";
      if (!stakingTxn.status) throw "Transaction Failed";
      if (stakingTxn.status) {
        await getUserBalance();
        ToastMessage("Success!", "Transaction successfull", "success");
      }
      setLoading(false);
    } catch (error) {
      console.log("error in handleStaking func", error);
      setLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const { amount } = data;
      await handleStaking(amount);
      setValue("amount", "");
    } catch (error) {
      console.log("error in onSubmit func", error);
    }
  };

  useEffect(() => {
    if (account && web3Provider && chain) {
      getUserBalance();
    }
  }, [account, web3Provider, chain]);
  return (
    <div>
      {loading && <Loader />}
      <Modal
        centered
        footer={false}
        open={connectModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
        width={700}
      >
        <ConnectModal closeModel={handleModalCancel} />
      </Modal>
      <Label>Amount:</Label>
      <div className="mt-2 form-relative-div">
        <Controller
          name="amount"
          control={control}
          rules={{
            required: "Amount is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "Invalid amount entered",
            },
            validate: {
              notZero: (value) =>
                parseFloat(value) !== 0 || "Amount must not be zero",
              sufficientAmount: (value) =>
                parseFloat(value) <= parseFloat(userBalance) ||
                "Amount exceeds balance",
            },
          }}
          render={({ field }) => (
            <Form.Control {...field} placeholder="0" disabled={!web3Provider} />
          )}
        />
        <Label
          className="cursor-label"
          onClick={() => {
            web3Provider && userBalance && setValue("amount", userBalance);
          }}
        >
          MAX
        </Label>
      </div>
      {errors && (
        <p className="error" style={{ color: "red", marginBottom: "0rem" }}>
          {errors.amount?.message}
        </p>
      )}
      <SmallText className="mt-2">
        Token Balance:{" "}
        {userBalance
          ? `${CommonUtility.numberWithCommas(userBalance)} A_C`
          : "--"}
      </SmallText>
      <YearlyAPYDiv>
        <Label>ETH APY</Label>
        <Label>1333%</Label>
      </YearlyAPYDiv>

      {web3Provider ? (
        <div
          className="btn-div address-btn-div"
          onClick={handleSubmit(onSubmit)}
        >
          <Image src={commonBtn} alt="button bg" />
          <h1>Stake</h1>
        </div>
      ) : (
        <div className="btn-div address-btn-div" onClick={handleModalOpen}>
          <Image src={commonBtn} alt="button bg" />
          <h1>Wallet</h1>
        </div>
      )}
    </div>
  );
}

export default Stake;
