import React from "react";
import {
  HomeCompWrapper,
  MainHeader,
  NavbarWrapper,
  OverLayDiv,
  Wrapper,
} from "./element";
import { Image } from "react-bootstrap";
import { bgOverlay, divider, navLogo } from "assets";
import SecondContainer from "./secondContainer";
import AddressComp from "./addressComp";
import MainTabsComp from "./mainTabsComp";

function HomeComp() {
  return (
    <HomeCompWrapper>
      <OverLayDiv>
        <Image src={bgOverlay} width={"100%"} height={"100%"} />
      </OverLayDiv>
      <Wrapper className="main-container ">
        <NavbarWrapper className="navbar">
          <Image src={navLogo} alt="Navbar logo" />
        </NavbarWrapper>
        <MainHeader>Dapp</MainHeader>

        <MainTabsComp />
        <SecondContainer />
        <AddressComp />
      </Wrapper>

      <Image src={divider} alt="Divider" />
    </HomeCompWrapper>
  );
}

export default HomeComp;
