import React from "react";
import { StakingHeader, TabsWrapper } from "./element";
import { useState } from "react";
import TabsButtonComp from "./tabsButtonComp";
import Stake from "./stake";
import Claim from "./claim";
import Withdraw from "./withdraw";
import { motion, AnimatePresence } from "framer-motion";

function MainTabsComp() {
  const [activeTab, setActiveTab] = useState("stake");

  return (
    <TabsWrapper>
      <StakingHeader>NOS (Novus Ordo Seclorum) Vault</StakingHeader>
      <TabsButtonComp activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="mt-4">
        <AnimatePresence mode="wait">
          {activeTab === "stake" ? (
            <motion.div
              style={{ marginTop: "2rem" }}
              key={"tab1"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
            >
              <Stake />
            </motion.div>
          ) : activeTab === "claim" ? (
            <motion.div
              style={{ marginTop: "2rem" }}
              key={"tab2"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
            >
              <Claim />
            </motion.div>
          ) : activeTab === "withdraw" ? (
            <motion.div
              style={{ marginTop: "2rem" }}
              key={"tab3"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
            >
              <Withdraw />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </TabsWrapper>
  );
}

export default MainTabsComp;
