import React from "react";
import { TabsButtonWrapper } from "./element";
import { Image } from "react-bootstrap";
import { tabActive, tabInactive } from "assets";

function TabsButtonComp({ activeTab, setActiveTab }) {
  return (
    <TabsButtonWrapper>
      <div
        className="btn-div tabs-header-btn"
        onClick={() => setActiveTab("stake")}
      >
        <Image
          src={activeTab === "stake" ? tabActive : tabInactive}
          alt="button bg"
        />
        <h1 className={`${activeTab === "stake" ? "active" : ""}`}>Stake</h1>
      </div>

      <div
        className="btn-div tabs-header-btn"
        onClick={() => setActiveTab("claim")}
      >
        <Image
          src={activeTab === "claim" ? tabActive : tabInactive}
          alt="button bg"
        />
        <h1 className={`${activeTab === "claim" ? "active" : ""}`}>Claim</h1>
      </div>

      <div
        className="btn-div tabs-header-btn"
        onClick={() => setActiveTab("withdraw")}
      >
        <Image
          src={activeTab === "withdraw" ? tabActive : tabInactive}
          alt="button bg"
        />

        <h1 className={`${activeTab === "withdraw" ? "active" : ""}`}>
          Withdraw
        </h1>
      </div>
    </TabsButtonWrapper>
  );
}

export default TabsButtonComp;
