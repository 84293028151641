import React, { useEffect, useState } from "react";
import {
  SecondContainerRightDiv,
  Label,
  SecondContainerLeftDiv,
  SecondContainerWrapper,
  SmallText,
  RightDivFlex,
} from "./element";
import { MainCol, MainRow, ToastMessage } from "components/common";
import { Form, Image } from "react-bootstrap";
import { cardDivider, commonBtn } from "assets";
import Web3 from "web3";
import environment from "environment";
import AnnuitService from "web3Functions/annuit";
import { CommonUtility } from "utility/common";
import { annuitAbi } from "utility/contract/annuitAbi";
import { DateUtility } from "utility/date";

function SecondContainer() {
  const [turn, setTurn] = useState(null);
  const [mintRate, setMintRate] = useState(null);
  const [burnRate, setBurnRate] = useState(null);
  const [isBurning, setIsBurning] = useState(false);
  const [totalSupply, setTotalSupply] = useState(null);
  const [macroContraction, setMacroContraction] = useState(false);
  const [airdropAddress, setAirdropAddress] = useState("");
  const [lastTxnAddress, setLastTxnAddress] = useState("");
  const [lastTxnDate, setLastTxnDate] = useState("");
  const [isEligible, setIsEligible] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [contractBalance, setContractBalance] = useState("");
  const [contractBurnPercent, setContractBurnPercent] = useState("");
  const [eoaBalance, setEoaBalance] = useState("");
  const [eoaBurnPercent, setEoaBurnPercent] = useState("");
  const [eoaBurnStatsInput, setEoaBurnStatsInput] = useState("");
  const [contractBurnStatsInput, setContractBurnStatsInput] = useState("");
  const [isValid, setIsValid] = useState({});

  useEffect(() => {
    getContractInfo();
  }, []);

  const handleEoaStatInput = (event) => {
    const value = event.target.value;
    setEoaBurnStatsInput(value);
    setEoaBurnPercent("");
    setEoaBalance("");

    setIsValid({
      ...isValid,
      eoaBurnStatsInput:
        value === "" ? false : !CommonUtility.isEthereumAddress(value),
    });
  };
  const handleAirdropInput = (event) => {
    const value = event.target.value;
    setAirdropAddress(value);
    setIsEligible("");
    setIsValid({
      ...isValid,
      airdropAddress:
        value === "" ? false : !CommonUtility.isEthereumAddress(value),
    });
  };
  const handleContractStatInput = (event) => {
    const value = event.target.value;
    setContractBurnStatsInput(value);
    setContractBurnPercent("");
    setContractBalance("");

    setIsValid({
      ...isValid,
      contractBurnStatsInput:
        value === "" ? false : !CommonUtility.isEthereumAddress(value),
    });
  };
  const getContractInfo = async () => {
    try {
      const chainId = environment.DEFAULT_CHAIN;
      const web3 = new Web3(environment.RPC[chainId]);
      const turn = await AnnuitService.turn(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );
      let mintRate = await AnnuitService.mintRate(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );
      let burnRate = await AnnuitService.burnRate(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );
      const isBurning = await AnnuitService.isBurning(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );
      let totalSupply = await AnnuitService.totalSupply(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );
      const macroContraction = await AnnuitService.macroContraction(
        web3,
        environment.ANNUIT_CONTRACT[chainId]
      );

      totalSupply = web3.utils.fromWei(
        totalSupply,
        environment.ANNUIT_DECIMALS_UNIT
      );
      mintRate = +mintRate / 10 ** environment.ANNUIT_DECIMALS;
      burnRate = +burnRate / 10 ** environment.ANNUIT_DECIMALS;
      setTurn(turn);
      setMintRate(mintRate);
      setBurnRate(burnRate);
      setIsBurning(isBurning ? "Yes" : "No");
      setTotalSupply(totalSupply);
      setMacroContraction(macroContraction ? "Yes" : "No");
    } catch (error) {
      console.log("error in getContractInfo func", error);
    }
  };

  const isAddressInArray = (addressArray, targetAddress) => {
    const lowercasedAddresses = addressArray.map((address) =>
      address.toLowerCase()
    );
    return lowercasedAddresses.includes(targetAddress.toLowerCase());
  };

  const checkEligibility = async () => {
    try {
      if (!CommonUtility.isEthereumAddress(airdropAddress))
        throw "Enter a valid address";
      const web3 = new Web3(environment.RPC[environment.DEFAULT_CHAIN]);
      const qualifiedAddresses = await AnnuitService.showQualifiedAddresses(
        web3,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      let isQualified = false;
      if (qualifiedAddresses?.length > 0) {
        isQualified = isAddressInArray(qualifiedAddresses, airdropAddress);
      }
      setIsEligible(isQualified ? "Eligible" : "Not Eligible");
      setShowResult(true);
    } catch (error) {
      console.log("error in checkEligibility func", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const contractBurnData = async (addressToCheck) => {
    try {
      if (!CommonUtility.isEthereumAddress(addressToCheck))
        throw "Enter a valid address";
      const web3 = new Web3(environment.RPC[environment.DEFAULT_CHAIN]);
      const isContract = await isContractAddress(addressToCheck, web3);
      if (!isContract) throw "Not a Contract Address";
      if (
        addressToCheck.toLowerCase() ==
        environment.UNISWAP_FACTORY[environment.DEFAULT_CHAIN].toLowerCase()
      )
        throw "This contract tokens aren't burnable";
      if (
        addressToCheck.toLowerCase() ==
        environment.UNISWAP_ROUTER[environment.DEFAULT_CHAIN].toLowerCase()
      )
        throw "This contract tokens aren't burnable";
      if (
        addressToCheck.toLowerCase() ==
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN].toLowerCase()
      )
        throw "This contract tokens aren't burnable";
      const addressBalanceInWei = await AnnuitService.balanceOf(
        web3,
        addressToCheck,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const addressBalance = web3.utils.fromWei(
        addressBalanceInWei,
        environment.ANNUIT_DECIMALS_UNIT
      );
      const currentTime = Date.now() / 1000;
      let burnAmount = 0;
      const shortTermTimeStamp = await AnnuitService.lastTxShortermBurnCounter(
        web3,
        addressToCheck,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const longTermTimeStamp = await AnnuitService.lastTxLongtermBurnCounter(
        web3,
        addressToCheck,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (
        +currentTime >
        +shortTermTimeStamp + environment.CONTRACT_ST_TIME_LIMT
      ) {
        burnAmount = await calculateBurnAmount(addressBalanceInWei, web3);
      } else if (
        currentTime >
        +longTermTimeStamp + environment.CONTRACT_LT_TIME_LIMT
      ) {
        burnAmount = addressBalance;
      } else {
        burnAmount = 0;
      }
      let burnPercent =
        +burnAmount == 0 ? 0 : (+burnAmount / +addressBalance) * 100;
      setContractBurnPercent(burnPercent);
      setContractBalance(addressBalance);
    } catch (error) {
      console.log("contractBurnData error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const addressBurnData = async (addressToCheck) => {
    try {
      if (!CommonUtility.isEthereumAddress(addressToCheck))
        throw "Enter a valid address";
      const web3 = new Web3(environment.RPC[environment.DEFAULT_CHAIN]);
      const contract = await CommonUtility.contract(
        web3,
        annuitAbi,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const isContract = await isContractAddress(addressToCheck, web3);
      console.log("isContract", isContract);
      if (isContract) throw "Not an EOA";
      const addressBalanceInWei = await contract.methods
        .balanceOf(addressToCheck)
        .call();

      const addressBalance = web3.utils.fromWei(
        addressBalanceInWei.toString(),
        environment.ANNUIT_DECIMALS_UNIT
      );
      const currentTime = Date.now() / 1000;
      let burnAmount = 0;
      const airdropAddress = await AnnuitService.airdropAddress(
        web3,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );

      if (addressToCheck.toLowerCase() == airdropAddress.toLowerCase()) {
        const lastTimeStamp = await AnnuitService.checkWhenLastUserTransaction(
          web3,
          addressToCheck,
          environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
        );
        if (
          currentTime >
          +lastTimeStamp + environment.AIRDROP_ADDRESS_TIME_LIMT
        ) {
          burnAmount = await calculateBurnAmount(addressBalanceInWei, web3);
        } else {
          burnAmount = 0;
        }
      } else {
        const shortTermTimeStamp =
          await AnnuitService.lastTxShortermBurnCounter(
            web3,
            addressToCheck,
            environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
          );
        const longTermTimeStamp = await AnnuitService.lastTxLongtermBurnCounter(
          web3,
          addressToCheck,
          environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
        );
        if (currentTime > +shortTermTimeStamp + environment.EOA_ST_TIME_LIMT) {
          burnAmount = await calculateBurnAmount(addressBalanceInWei, web3);
        } else if (
          currentTime >
          +longTermTimeStamp + environment.EOA_LT_TIME_LIMT
        ) {
          burnAmount = addressBalance;
        } else {
          burnAmount = 0;
        }
      }
      console.log("Burn Amount", burnAmount);
      let burnPercent =
        +burnAmount == 0 ? 0 : (+burnAmount / +addressBalance) * 100;
      setEoaBurnPercent(burnPercent);
      setEoaBalance(addressBalance);
    } catch (error) {
      console.log("addressBurnData error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const calculateBurnAmount = async (addressBalance, web3) => {
    try {
      const inactiveBurn = await AnnuitService.inactiveBurn(
        web3,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      console.log("VALUESS", inactiveBurn, addressBalance);
      let burnAmountInWei =
        (+addressBalance * +inactiveBurn) / 10 ** environment.ANNUIT_DECIMALS;
      const burnAmount = web3.utils.fromWei(
        burnAmountInWei.toString(),
        environment.ANNUIT_DECIMALS_UNIT
      );
      return burnAmount;
    } catch (error) {
      console.log("calculateBurnAmount error", error);
    }
  };
  const isContractAddress = async (address, web3) => {
    try {
      const code = await web3.eth.getCode(address);
      if (code.length > 2) {
        console.log("yes it is contract");
        return true;
      } else {
        console.log("no it is not contract");
        return false;
      }
    } catch (error) {
      console.log("isContractAddress function error", error);
      return false;
    }
  };

  const handleLastTxnInput = (event) => {
    const value = event.target.value;
    setLastTxnAddress(value);
    setLastTxnDate(null);
    setIsValid({
      ...isValid,
      lastTxnDate:
        value === "" ? false : !CommonUtility.isEthereumAddress(value),
    });
  };

  const getLastTxn = async () => {
    try {
      if (!CommonUtility.isEthereumAddress(lastTxnAddress))
        throw "Enter a valid address";
      const web3 = new Web3(environment.RPC[environment.DEFAULT_CHAIN]);
      const lastTimeStamp = await AnnuitService.checkWhenLastUserTransaction(
        web3,
        lastTxnAddress,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (+lastTimeStamp == 0) {
        setLastTxnDate("0/0/0000, 0:00");
      } else {
        const formattedDate = DateUtility.convertUnixTimestamp(lastTimeStamp);
        setLastTxnDate(formattedDate);
      }
    } catch (error) {
      console.log("error in getLastTxn func", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  return (
    <div>
      <SecondContainerWrapper>
        <MainRow>
          <MainCol xl={7} xxl={8}>
            <SecondContainerLeftDiv>
              {/* Eligibility */}
              <Label>ANNUIT ADEQUACY:</Label>
              <MainRow className="mt-2">
                <MainCol sm={8} xl={9} xxl={10}>
                  <Form.Control
                    placeholder="0x12321"
                    value={airdropAddress}
                    onChange={handleAirdropInput}
                    isInvalid={!isValid.airdropAddress}
                  />
                  {isValid.airdropAddress && (
                    <p
                      className="error"
                      style={{ color: "red", marginBottom: "0rem" }}
                    >
                      Invalid address
                    </p>
                  )}
                  <div className="flex-div">
                    <SmallText>
                      {" "}
                      {showResult && isEligible
                        ? `Status: ${showResult && isEligible}`
                        : ""}
                    </SmallText>
                  </div>
                </MainCol>
                <MainCol sm={4} xl={3} xxl={2}>
                  <div
                    className={`btn-div ${
                      lastTxnAddress == environment.ZERO_ADDRESS ||
                      !CommonUtility.isEthereumAddress(airdropAddress)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      if (!isValid.airdropAddress && airdropAddress) {
                        checkEligibility();
                      }
                    }}
                  >
                    <Image src={commonBtn} alt="button bg" />
                    <h1>Search</h1>
                  </div>
                </MainCol>
              </MainRow>

              {/* Last Transaction */}

              <Label>CHECK WALLET'S ACTIVITY:</Label>
              <MainRow className="mt-2">
                <MainCol sm={8} xl={9} xxl={10}>
                  <Form.Control
                    placeholder="0x12321"
                    value={lastTxnAddress}
                    onChange={handleLastTxnInput}
                    isValid={!isValid.lastTxnDate}
                  />
                  {isValid.lastTxnDate && (
                    <p
                      className="error"
                      style={{ color: "red", marginBottom: "0rem" }}
                    >
                      Invalid address
                    </p>
                  )}
                  <div className="flex-div">
                    <SmallText>
                      {lastTxnDate ? `Timestamp: ${lastTxnDate}` : ""}
                    </SmallText>
                  </div>
                </MainCol>
                <MainCol sm={4} xl={3} xxl={2}>
                  <div
                    className={`btn-div ${
                      lastTxnAddress == environment.ZERO_ADDRESS ||
                      !CommonUtility.isEthereumAddress(lastTxnAddress)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      if (!isValid.lastTxnAddress && lastTxnAddress) {
                        getLastTxn();
                      }
                    }}
                  >
                    <Image src={commonBtn} alt="button bg" />
                    <h1>Query</h1>
                  </div>
                </MainCol>
              </MainRow>

              {/* Burn Status */}

              <Label>Check Contract's Activity:</Label>

              <MainRow className="mt-2">
                <MainCol sm={8} xl={9} xxl={10}>
                  <Form.Control
                    type="text"
                    placeholder="0x12321"
                    value={contractBurnStatsInput}
                    onChange={handleContractStatInput}
                    isInvalid={!isValid.contractBurnStatsInput}
                  />
                  {isValid.contractBurnStatsInput && (
                    <p
                      className="error"
                      style={{ color: "red", marginBottom: "0rem" }}
                    >
                      Invalid address
                    </p>
                  )}
                  <div className="flex-div">
                    <SmallText>
                      {contractBalance
                        ? `Balance: ${CommonUtility.numberWithCommas(
                            contractBalance
                          )}`
                        : ""}
                    </SmallText>
                    <SmallText>
                      {contractBalance ? `Burn: ${contractBurnPercent}%` : ""}
                    </SmallText>
                  </div>
                </MainCol>
                <MainCol sm={4} xl={3} xxl={2}>
                  <div
                    className={`btn-div ${
                      lastTxnAddress == environment.ZERO_ADDRESS ||
                      !CommonUtility.isEthereumAddress(contractBurnStatsInput)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        !isValid.contractBurnStatsInput &&
                        contractBurnStatsInput
                      ) {
                        contractBurnData(contractBurnStatsInput);
                      }
                    }}
                  >
                    <Image src={commonBtn} alt="button bg" />
                    <h1>Query</h1>
                  </div>
                </MainCol>
              </MainRow>

              {/*Address Burn Status */}

              <Label>Coeptis Combustion state:</Label>

              <MainRow className="mt-2">
                <MainCol sm={8} xl={9} xxl={10}>
                  <Form.Control
                    type="text"
                    placeholder="0x12321"
                    value={eoaBurnStatsInput}
                    onChange={handleEoaStatInput}
                    isInvalid={!isValid.eoaBurnStatsInput}
                  />
                  {isValid.eoaBurnStatsInput && (
                    <p
                      className="error"
                      style={{ color: "red", marginBottom: "0rem" }}
                    >
                      Invalid address
                    </p>
                  )}
                  <div className="flex-div">
                    <SmallText>
                      {eoaBalance
                        ? `Balance: ${CommonUtility.numberWithCommas(
                            eoaBalance
                          )} ANNUIT`
                        : ""}
                    </SmallText>
                    <SmallText>
                      {eoaBalance ? `Burn: ${eoaBurnPercent}%` : ""}
                    </SmallText>
                  </div>
                </MainCol>
                <MainCol sm={4} xl={3} xxl={2}>
                  <div
                    className={`btn-div ${
                      lastTxnAddress == environment.ZERO_ADDRESS ||
                      !CommonUtility.isEthereumAddress(eoaBurnStatsInput)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      if (!isValid.eoaBurnStatsInput && eoaBurnStatsInput) {
                        addressBurnData(eoaBurnStatsInput);
                      }
                    }}
                  >
                    <Image src={commonBtn} alt="button bg" />
                    <h1>Query</h1>
                  </div>
                </MainCol>
              </MainRow>
            </SecondContainerLeftDiv>
          </MainCol>
          <MainCol xl={5} xxl={4}>
            <SecondContainerRightDiv>
              <RightDivFlex>
                <div className="inner-div">
                  <Label>MINT RATE</Label>
                  {/* <SmallText>{mintRate ? `${mintRate} %` : "--"}</SmallText> */}
                  <SmallText> 0%</SmallText>

                  <Image src={cardDivider} alt="card divder line" />
                </div>

                <div className="inner-div">
                  <Label>COEPTIS Combustion</Label>
                  {/* <SmallText>{isBurning ? isBurning : "--"}</SmallText> */}
                  <SmallText>Yes</SmallText>

                  <Image src={cardDivider} alt="card divder line" />
                </div>

                <div className="inner-div">
                  <Label>EPOCH</Label>
                  <SmallText>0</SmallText>

                  {/* <SmallText>{turn ? turn : "--"}</SmallText> */}
                  <Image src={cardDivider} alt="card divder line" />
                </div>

                <div className="inner-div">
                  <Label>MACRO CONTRACTION</Label>
                  <SmallText>
                    Yes
                    {/* {macroContraction ? macroContraction : "--"} */}
                  </SmallText>
                  <Image src={cardDivider} alt="card divder line" />
                </div>

                <div className="inner-div">
                  <Label>Burn rate</Label>
                  <SmallText>
                    {/* {burnRate ? `${burnRate} %` : "--"} */}
                    0.5%
                  </SmallText>
                  <Image src={cardDivider} alt="card divder line" />
                </div>

                <div className="inner-div">
                  <Label>TOTAL SUPPLY</Label>
                  <SmallText>
                    {totalSupply
                      ? `${CommonUtility.numberWithCommas(totalSupply)} A_C`
                      : "--"}
                  </SmallText>
                </div>
              </RightDivFlex>
            </SecondContainerRightDiv>
          </MainCol>
        </MainRow>
      </SecondContainerWrapper>
    </div>
  );
}

export default SecondContainer;
