import { MainModel, ModelsData, ConnectButton } from "./connectModalElement";
import { metamask, walletConnect } from "../../../../assets";
import { useWalletConnectClient } from "services/walletServices";
import environment from "environment";
import { Image } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";

const ConnectModal = ({ closeModel }) => {
  const { connect, connectWithInjected, account } = useWalletConnectClient();

  const handleWeb3MetaMask = async () => {
    connectWithInjected(environment.DEFAULT_CHAIN);
    closeModel();
  };
  const handleWeb3WalletConnect = async () => {
    connect(`eip155:${environment.DEFAULT_CHAIN}`);
    closeModel();
  };

  return (
    <MainModel>
      <IoIosClose
        className="close-icon"
        fontSize={32}
        color="#000"
        onClick={closeModel}
      />
      <div className="overlay" />
      <ModelsData>
        <ConnectButton onClick={() => handleWeb3MetaMask()}>
          <Image src={metamask} />
          <span>Metamask</span>
        </ConnectButton>
        <ConnectButton onClick={() => handleWeb3WalletConnect()}>
          <Image src={walletConnect} />
          <span>WalletConnect</span>
        </ConnectButton>
      </ModelsData>
    </MainModel>
  );
};

export default ConnectModal;
