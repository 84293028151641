import styled from "styled-components";

const brownish = "#4C5849";

export const HomeCompWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  border-left: 3px solid ${brownish};
  border-right: 3px solid ${brownish};
  min-height: 100vh;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: 100px;
  margin-right: 100px;
  position: relative;
  z-index: 10;
  padding-bottom: 4rem;
  width: 100%;

  @media (max-width: 992px) {
    border: none;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0;
  }

  .btn-div {
    position: relative;
    width: 100%;
    height: 68.681px;
    cursor: pointer;

    &.disabled {
      color: red;
      cursor: not-allowed;
    }

    @media (max-width: 576px) {
      width: 149.031px;
      margin: 0rem auto 2rem auto;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    h1 {
      color: #e7e2d5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      top: 41%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .form-control {
    background: #dfe4d4;
    box-shadow: 0.774px -0.774px 8.4px 0px #93978d inset;
    height: 54px;
    border: none;
    border-radius: 0;

    &::placeholder {
      color: #bbb;
    }

    &:focus {
      box-shadow: none;
      box-shadow: 2.774px -2.774px 10.4px 0px #93978d inset;
      border: none;
    }
  }

  .flex-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .address-btn-div {
    width: 149.031px;
    margin: 2rem auto 0rem auto;
  }

  .tabs-header-btn {
    width: 150.031px;
    height: 40px;

    @media (max-width: 576px) {
      width: 120px;
      margin: 0 !important;
    }

    h1 {
      color: #bbb;
      transition: 300ms ease-in-out all;

      @media (max-width: 576px) {
        font-size: 9px;
      }

      &.active {
        color: #e7e2d5;
        transition: 300ms ease-in-out all;
      }
    }
  }

  .form-relative-div {
    position: relative;

    .form-control {
      padding-right: 4rem;
    }
  }

  .margin-less {
    margin: 0 !important;
    width: 80px;
    height: 40px;

    h1 {
      font-size: 16px;
    }
  }
`;

export const NavbarWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverLayDiv = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: multiply;
  box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.25) inset;
`;

export const MainHeader = styled.h1`
  color: #4c5849;
  text-shadow: 1px 4px 0px #eef2e8, 3px 8px 0px #93978d;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #ecefdc;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
`;

export const SecondContainerWrapper = styled.div`
  margin-top: 4rem;
`;

export const SecondContainerLeftDiv = styled.div`
  background: #dfe4d4;
  box-shadow: 0.772px -0.772px 19.54px 0px #93978d inset;
  height: 580px;
  padding: 2rem;

  @media (max-width: 1200px) {
    height: auto;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const SecondContainerRightDiv = styled(SecondContainerLeftDiv)`
  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

export const Label = styled.p`
  color: #4c5849;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;

  &.cursor-label {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

export const SmallText = styled.p`
  color: #4c5849;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RightDivFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 4px;

  .inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    position: relative;

    @media (max-width: 1200px) {
      margin-top: 2rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export const AddressWrapper = styled.div`
  margin-top: 4rem;
  background: #dfe4d4;
  box-shadow: 0.772px -0.772px 19.54px 0px #93978d inset;
  padding: 2rem;

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

export const AddressHeader = styled.h1`
  color: #4c5849;
  text-shadow: 1.333px 3.556px 0px #93978d, 0.444px 1.778px 0px #eef2e8;
  -webkit-text-stroke-width: 0.4444444477558136;
  -webkit-text-stroke-color: #ecefdc;
  font-size: 30.222px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 2px 0px #eef2e8, 3px 4px 0px #93978d;
`;

export const TabsWrapper = styled.div`
  margin-top: 4rem;
  background: #dfe4d4;
  box-shadow: 0.772px -0.772px 19.54px 0px #93978d inset;
  padding: 2rem;
  min-height: 500px;

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

export const StakingHeader = styled(AddressHeader)``;

export const TabsButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 576px) {
    gap: 5px;
  }
`;

export const YearlyAPYDiv = styled.div`
  background: #dfe4d4;
  box-shadow: 0.774px -0.774px 8.4px 0px #93978d inset;
  height: 54px;
  margin-top: 1.5rem;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClaimWrapper = styled.div`
  .inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 2rem;

    &:first-of-type {
      margin-top: 0;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 50%;
    }
  }
`;

export const WithdrawWrapper = styled.div`
  .inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 2rem;

    &:first-of-type {
      margin-top: 0;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 50%;
    }
  }
`;

export const WithdrawBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 1rem;
`;
