const environment = {
  BACKEND_BASE_URL: "",
  MORALIS_APP_ID: "Cong0Rh8UwDPV5HC4U0nCPxiwEz5PAxeFLP2f3i0",
  MORALIS_SERVER_URL: "https://nbnob0befkpo.usemoralis.com:2053/server",
  STAKING_CONTRACT: {
    1: "0xd1927b0813A4413494C414b15fEDc05408c03b04",
    "eip155:1": "0xd1927b0813A4413494C414b15fEDc05408c03b04",
    5: "0x85111E4158d706EC23AebE2B19ceE595D8d0932d",
    "eip155:5": "0x85111E4158d706EC23AebE2B19ceE595D8d0932d",
  },
  ANNUIT_CONTRACT: {
    1: "0x51CFB9d541f3C9dA9BDaf1f429a929FeE186A4D8",
    "eip155:1": "0x51CFB9d541f3C9dA9BDaf1f429a929FeE186A4D8",
    5: "0x9Ea0123d04f21a93ca731897fdd109C044473Cb9",
    "eip155:5": "0x9Ea0123d04f21a93ca731897fdd109C044473Cb9",
  },

  RPC: {
    5: "https://ethereum-goerli.publicnode.com",
    "eip155:5": "https://ethereum-goerli.publicnode.com",
    1: "https://mainnet.infura.io/v3/3f5f69960f584c2986b1011c8f33d8e6",
    "eip155:1": "https://mainnet.infura.io/v3/3f5f69960f584c2986b1011c8f33d8e6",
  },
  UNISWAP_FACTORY: {
    5: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    "eip155:5": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    1: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    "eip155:1": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    43114: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    "eip155:43114": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    1088: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    "eip155:1088": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  },
  UNISWAP_ROUTER: {
    5: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    "eip155:5": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    "eip155:1": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    43114: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    "eip155:43114": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    1088: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    "eip155:1088": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  },

  ANNUIT_DECIMALS: 9,
  ANNUIT_DECIMALS_UNIT: "gwei",
  DEFAULT_CHAIN: 1,
  DEFAULT_HEX_CHAIN: "0x1",
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
  DENOMINATOR: 10000,
  AIRDROP_ADDRESS_TIME_LIMT: 604800,
  EOA_ST_TIME_LIMT: 604800,
  EOA_LT_TIME_LIMT: 1209600,
  CONTRACT_ST_TIME_LIMT: 604800,
  CONTRACT_LT_TIME_LIMT: 1209600,
};
export default environment;
