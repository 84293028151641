export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

//New assets

export { default as bgOverlay } from "./images/bgOverlay.png";
export { default as tabActive } from "./images/tabActive.png";
export { default as tabInactive } from "./images/tabInactive.png";
export { default as navLogo } from "./images/navLogo.png";
export { default as commonBtn } from "./images/commonBtn.png";
export { default as cardDivider } from "./images/cardDivider.svg";
export { default as divider } from "./images/divider.png";
export { default as modalBG } from "./images/modalBG.png";
export { default as metamask } from "./images/metamask.png";
export { default as walletConnect } from "./images/walletConnect.png";
export { default as loaderBricks } from "./images/loaderBricks.png";
export { default as loaderEye } from "./images/loaderEye.png";
export { default as loaderPlainTriangle } from "./images/loaderPlainTriangle.png";
export { default as loaderHeadTriangle } from "./images/loaderHeadTriangle.png";
