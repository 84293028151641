import styled from "styled-components";
import { modalBG } from "assets";

export const MainModel = styled.div`
  height: 300px;
  background: #dfe4d4;
  box-shadow: 0.772px -0.772px 19.54px 0px #93978d inset;
  background-image: url(${modalBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    z-index: 2;
  }

  .overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: #dfe4d4;
    opacity: 20%;

    @media (max-width: 768px) {
      opacity: 80%;
    }
  }
`;

export const ModelsData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 1rem 4.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
    gap: 2rem;
    justify-content: center;
  }
`;

export const ConnectButton = styled.button`
  border-radius: 331.897px;
  background: #dfe4d4;
  box-shadow: 0.825px -0.825px 20.877px 0px #93978d inset;
  border: none;
  width: 150px;
  height: 150.897px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (max-width: 350px) {
    width: 120px;
    height: 120px;
  }

  img {
    width: 70px;
  }

  span {
    color: #4c5849;
    text-shadow: 0.314px 1.255px 0px #eef2e8, 0.941px 2.51px 0px #93978d;
    -webkit-text-stroke-width: 0.313776433467865;
    -webkit-text-stroke-color: #ecefdc;
    font-family: "Dollar Bill";
    font-size: 10.513px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
`;

export const ConnectIcon = styled.img``;
