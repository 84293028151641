import { CommonUtility } from "utility/common";
import { annuitAbi } from "utility/contract/annuitAbi";

class Annuit {
  //   <<<<--- READ FUNCTIONS --->>>>
  async turn(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.turn().call();
    } catch (error) {
      console.log("error in turn func", error);
      return error;
    }
  }

  async mintRate(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.mintRate().call();
    } catch (error) {
      console.log("error in mintRate func", error);
      return error;
    }
  }

  async burnRate(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.burnRate().call();
    } catch (error) {
      console.log("error in burnRate func", error);
      return error;
    }
  }
  async isBurning(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.isBurning().call();
    } catch (error) {
      console.log("error in isBurning func", error);
      return error;
    }
  }
  async macroContraction(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.macroContraction().call();
    } catch (error) {
      console.log("error in macroContraction func", error);
      return error;
    }
  }

  async totalSupply(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.totalSupply().call();
    } catch (error) {
      console.log("error in totalSupply func", error);
      return error;
    }
  }
  async balanceOf(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.balanceOf(account).call();
    } catch (error) {
      console.log("error in balanceOf func", error);
      return error;
    }
  }

  async showQualifiedAddresses(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.showQualifiedAddresses().call();
    } catch (error) {
      console.log("error in showQualifiedAddresses func", error);
      return error;
    }
  }
  async checkWhenLastUserTransaction(web3, addressToCheck, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods
        .checkWhenLast_USER_Transaction(addressToCheck)
        .call();
    } catch (error) {
      console.log("error in checkWhenLastUserTransaction func", error);
      return error;
    }
  }
  async lastTxShortermBurnCounter(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods
        .LAST_TX_SHORTERM_BURN_COUNTER(account)
        .call();
    } catch (error) {
      console.log("error in lastTxShortermBurnCounter func", error);
      return error;
    }
  }
  async lastTxLongtermBurnCounter(web3, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods
        .LAST_TX_LONGTERM_BURN_COUNTER(account)
        .call();
    } catch (error) {
      console.log("error in lastTxLongtermBurnCounter func", error);
      return error;
    }
  }
  async inactiveBurn(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.inactive_burn().call();
    } catch (error) {
      console.log("error in inactiveBurn func", error);
      return error;
    }
  }
  async airdropAddress(web3, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods.airdropAddress().call();
    } catch (error) {
      console.log("error in airdropAddress func", error);
      return error;
    }
  }

  //   <<<<--- WRITE FUNCTIONS ---->>>>

  async burnInactiveAddress(web3, addressToBurn, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, annuitAbi, contractAddress);
      return await contract.methods
        .burn_Inactive_Address(addressToBurn)
        .send({ from: account });
    } catch (error) {
      console.log("error in burnInactiveAddress func", error);
      return error;
    }
  }
}

const AnnuitService = new Annuit();
Object.freeze(AnnuitService);
export default AnnuitService;
