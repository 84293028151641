import React, { useState } from "react";
import { AddressWrapper, AddressHeader } from "./element";
import { Form, Image } from "react-bootstrap";
import { commonBtn } from "assets";
import { Modal } from "antd";
import { useWalletConnectClient } from "services/walletServices";
import ConnectModal from "components/common/modal/connectModel/connectModal";
import { Loader, ToastMessage } from "components/common";
import AnnuitService from "web3Functions/annuit";
import environment from "environment";
import { CommonUtility } from "utility/common";
import { useForm, Controller } from "react-hook-form";

function AddressComp() {
  const [connectModal, setConnectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { web3Provider, account } = useWalletConnectClient();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      eoaInput: "",
    },
  });

  const handleModalOpen = () => {
    setConnectModal(true);
  };
  const handleModalCancel = () => {
    setConnectModal(false);
  };

  const isContractAddress = async (address, web3) => {
    try {
      const code = await web3.eth.getCode(address);
      if (code.length > 2) {
        console.log("yes it is contract");
        return true;
      } else {
        console.log("no it is not contract");
        return false;
      }
    } catch (error) {
      console.log("isContractAddress function error", error);
      return false;
    }
  };

  const burnEOATokens = async (eoaInput) => {
    try {
      setLoading(true);
      const isContract = await isContractAddress(eoaInput, web3Provider);
      if (isContract) throw "Not an EOA";
      const balanceInWei = await AnnuitService.balanceOf(
        web3Provider,
        eoaInput,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (+balanceInWei <= 0) throw "Zero Balance";
      const airdropAddress = await AnnuitService.airdropAddress(
        web3Provider,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (eoaInput.toLowerCase() == airdropAddress.toLowerCase()) {
        const currentTime = Date.now() / 1000;
        const lastTimeStamp = await AnnuitService.checkWhenLastUserTransaction(
          web3Provider,
          eoaInput,
          environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
        );
        if (
          currentTime <
          +lastTimeStamp + environment.AIRDROP_ADDRESS_TIME_LIMT
        )
          throw "Airdrop address is Active form last 3 days";
      }
      const txn = await AnnuitService.burnInactiveAddress(
        web3Provider,
        eoaInput,
        account,
        environment.ANNUIT_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setLoading(false);
        ToastMessage("Success!", "Transaction successfull", "success");
      }
    } catch (error) {
      console.log("burnEOATokens func error", error);
      setLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const onSubmit = async (data) => {
    try {
      const { eoaInput } = data;
      await burnEOATokens(eoaInput);
      setValue("eoaInput", "");
    } catch (error) {
      console.log("error in onSubmit func", error);
    }
  };

  return (
    <AddressWrapper>
      {loading && <Loader />}
      <Modal
        centered
        width={700}
        footer={false}
        open={connectModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
      >
        <ConnectModal closeModel={handleModalCancel} />
      </Modal>
      <AddressHeader>Enter Coeptis Combustion Wallet</AddressHeader>
      <Controller
        name="eoaInput"
        control={control}
        rules={{
          validate: {
            isValidAddress: (value) =>
              value == environment.ZERO_ADDRESS ||
              !CommonUtility.isEthereumAddress(value)
                ? "Enter a valid address"
                : undefined,
          },
        }}
        render={({ field }) => (
          <Form.Control {...field} className="mt-4" placeholder="0x12dsf" />
        )}
      />

      {errors && (
        <p className="error" style={{ color: "red", marginBottom: "0rem" }}>
          {errors.eoaInput?.message}
        </p>
      )}

      {web3Provider ? (
        <div
          className="btn-div address-btn-div "
          onClick={handleSubmit(onSubmit)}
        >
          <Image src={commonBtn} alt="button bg" />
          <h1>Burn</h1>
        </div>
      ) : (
        <div className="btn-div address-btn-div" onClick={handleModalOpen}>
          <Image src={commonBtn} alt="button bg" />
          <h1>Wallet</h1>
        </div>
      )}
    </AddressWrapper>
  );
}

export default AddressComp;
