import React, { useEffect, useState } from "react";
import { ClaimWrapper, Label } from "./element";
import { Image } from "react-bootstrap";
import { Modal } from "antd";
import { cardDivider, commonBtn } from "assets";
import { useWalletConnectClient } from "services/walletServices";
import ConnectModal from "components/common/modal/connectModel/connectModal";
import { Loader, ToastMessage } from "components/common";
import StakingService from "web3Functions/staking";
import environment from "environment";
import { CommonUtility } from "utility/common";

function Claim() {
  const [connectModal, setConnectModal] = useState(false);
  const [claimableRewards, setClaimableRewards] = useState("");
  const [stakedAmount, setStakedAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const { account, web3Provider, chain } = useWalletConnectClient();

  const handleModalOpen = () => {
    setConnectModal(true);
  };
  const handleModalCancel = () => {
    setConnectModal(false);
  };

  const getUserStakedAmount = async () => {
    try {
      const stakeAmount = await StakingService.getUserStakes(
        web3Provider,
        account,
        environment.STAKING_CONTRACT[+chain]
      );
      const stakeAmountInEth = web3Provider.utils.fromWei(
        stakeAmount.amount,
        environment.ANNUIT_DECIMALS_UNIT
      );
      setStakedAmount(stakeAmountInEth);
    } catch (error) {
      console.log("error in getUserStakedAmount func", error);
      throw error;
    }
  };
  const getClaimableRewards = async () => {
    try {
      const rewards = await StakingService.calculateRewards(
        web3Provider,
        account,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (!rewards) throw "Error while fetching user claimable rewards ";
      const rewardsInEth = web3Provider.utils.fromWei(
        rewards,
        environment.ANNUIT_DECIMALS_UNIT
      );
      setClaimableRewards(rewardsInEth);
    } catch (error) {
      console.log("error in getClaimableRewards func", error);
      throw error;
    }
  };

  const handleClaimRewards = async () => {
    try {
      setLoading(true);
      const claimTxn = await StakingService.claimRewards(
        web3Provider,
        account,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      if (claimTxn?.code === 4001) throw "User denied transaction!";
      if (!claimTxn.status) throw "Transaction Failed";
      if (claimTxn.status) {
        await getClaimableRewards();
        ToastMessage("Success!", "Transaction successfull", "success");
      }
      setLoading(false);
    } catch (error) {
      console.log("error in handleClaimRewards func", error);
      setLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const onSubmit = async () => {
    try {
      await handleClaimRewards();
    } catch (error) {
      console.log("error in onSubmit func", error);
    }
  };

  useEffect(() => {
    if (account && web3Provider && chain) {
      getClaimableRewards();
      getUserStakedAmount();
    }
  }, [account, web3Provider, chain]);

  useEffect(() => {
    if (account && web3Provider && chain) {
      const interval = setInterval(() => {
        getClaimableRewards();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [account, web3Provider, chain]);

  return (
    <ClaimWrapper>
      {loading && <Loader />}
      <Modal
        centered
        width={700}
        footer={false}
        open={connectModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
      >
        <ConnectModal closeModel={handleModalCancel} />
      </Modal>

      <div className="inner-div">
        <Label>Amount staked</Label>
        <Label>
          {stakedAmount
            ? `${CommonUtility.numberWithCommas(stakedAmount)} A_C`
            : "--"}
        </Label>
        <Image src={cardDivider} alt="card divder line" width={"100%"} />
      </div>
      <div className="inner-div">
        <Label>Claimable</Label>
        <Label>{claimableRewards ? `${claimableRewards} ETH` : "--"}</Label>
      </div>

      {web3Provider ? (
        <div
          className="btn-div address-btn-div"
          onClick={() => {
            if (parseFloat(claimableRewards) > 0) {
              onSubmit();
            }
          }}
        >
          <Image src={commonBtn} alt="button bg" />
          <h1>Claim</h1>
        </div>
      ) : (
        <div className="btn-div address-btn-div" onClick={handleModalOpen}>
          <Image src={commonBtn} alt="button bg" />
          <h1>Wallet</h1>
        </div>
      )}
    </ClaimWrapper>
  );
}

export default Claim;
